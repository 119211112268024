@font-face {
    font-family: "Pretendard";
    src: url(./fonts/Pretendard-Regular.otf);
    font-weight: 400, normal;
    font-display: block;
}

@font-face {
    font-family: "Pretendard";
    src: url(./fonts/Pretendard-Medium.otf);
    font-weight: 500;
    font-display: block;
}

@font-face {
    font-family: "Pretendard";
    src: url(./fonts/Pretendard-SemiBold.otf);
    font-weight: 600;
    font-display: block;
}

@font-face {
    font-family: "Pretendard";
    src: url(./fonts/Pretendard-Bold.otf);
    font-weight: 700;
}

* {
    font-family: Pretendard, sans-serif !important;
}
